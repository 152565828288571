/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n": types.GiftCardFragmentFragmentDoc,
    "\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n": types.FindGiftcardQueryDocument,
    "\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n": types.FindGiftCardExternalDocumentDocument,
    "\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n": types.InitMembershipTransactionDocument,
    "\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n": types.FinalizeMembershipTransactionDocument,
    "\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n": types.ConversionRatesFragmentDoc,
    "\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n": types.ErrorFragmentFragmentDoc,
    "\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n": types.GetCurrencyRatesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n"): (typeof documents)["\n  fragment GiftCardFragment on GiftCardGetGiftCardResponse {\n    number\n    status\n    validTo\n    externalDocumentNo\n    amount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation findGiftcardQuery(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          ...GiftCardFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation FindGiftCardExternalDocument(\n    $input: BookingFlowFindGiftCardRequestModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postBookingflowServerGiftcardFind(input: $input) {\n          externalDocumentNo\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InitMembershipTransaction(\n    $input: BookingFlowOrderMembershipTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionInit(input: $input) {\n          transactionId\n          membershipId\n          paymentTransactionId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation FinalizeMembershipTransaction(\n    $input: BookingFlowOrderMembershipFinalizeTransactionModelInput!\n  ) {\n    Bluelagoon {\n      Booking {\n        postMembershipTransactionFinalize(input: $input) {\n          authorizationCode\n          transactionId\n          cardHolder {\n            id\n            firstName\n            lastName\n            personalId\n            email\n            phoneNumber\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n"): (typeof documents)["\n  fragment ConversionRates on PaymentPlanetCurrencyConversionRate {\n    currency\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n"): (typeof documents)["\n  fragment ErrorFragment on MicrosoftAspNetCoreMvcProblemDetails {\n    title\n    status\n    detail\n    type\n    instance\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCurrencyRates {\n    Bluelagoon {\n      Booking {\n        paymentCurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n        paymentMulticurrencyRates {\n          ... on PaymentPlanetCollectCurrencyConversionRatesResponse {\n            conversionRates {\n              reportDetail {\n                retrievalReferenceNumber\n              }\n              rates {\n                ...ConversionRates\n              }\n            }\n          }\n          ...ErrorFragment\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;